import React from "react";
import ClientPC from "./img/Clients/ClientsA.png";
import ClientMB from "./img/Clients/ClientsB.png";
function Clients() {
  return (
    <>
      <section id="clients">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Clients</h2>
            <p>
              Unlocking Potential, Building Futures: Our Clients, Our
              Inspiration, Your Success Story Begins Here.
            </p>
            <img src={ClientPC} id="clientsPc" class="img-fluid" />
            <img src={ClientMB} id="clientsMb" class="img-fluid" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Clients;
