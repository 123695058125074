import React, { useState } from "react";
import { Table } from "react-bootstrap";
import M1 from "./img/Machines/1.png";
import M2 from "./img/Machines/2.png";
import M3 from "./img/Machines/3.png";
import M4 from "./img/Machines/4.png";
import M5 from "./img/Machines/5.png";
import M6 from "./img/Machines/6.png";
import M7 from "./img/Machines/7.png";
import M8 from "./img/Machines/8.png";
import M9 from "./img/Machines/9.png";
import M10 from "./img/Machines/10.png";
import M11 from "./img/Machines/11.png";
function Machines() {
  // State to track which div to show
  const [activeDiv, setActiveDiv] = useState(1);

  // Function to handle button click
  const handleButtonClick = (divNumber) => {
    setActiveDiv(divNumber);
  };
  return (
    <>
      <section id="portfolio" class="portfolio">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Machines</h2>
          </div>
        </div>

        <div className="container">
          <div style={{ display: activeDiv === 1 ? "block" : "none" }}>
            {/* ///Machine 1 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Machine</td>
                      <td>CNC Turning Centre</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Ace Micromatic</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Model Number</td>
                      <td>J 300 LM</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Maximum Turning Diameter</td>
                      <td>320 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Maximum Turning Length</td>
                      <td>300 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Spindle Size</td>
                      <td>A2-5</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Swing Over Bed</td>
                      <td>500 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Swing Over Carriage</td>
                      <td>250 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Distance Between Centers</td>
                      <td>312 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Bore Through Spindle</td>
                      <td>52.5 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Max Spindle Speed</td>
                      <td>4000 rpm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M1} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 1 */}
            <br></br> <br></br>
            {/* ///Machine 2 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2</td>
                      <td>Machine</td>
                      <td>CNC Turning Centre</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Ace Micromatic</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Model Number</td>
                      <td>Jobber XL</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Maximum Turning Diameter</td>
                      <td>320 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Maximum Turning Length</td>
                      <td>400 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Spindle Size</td>
                      <td>A2-5</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Swing Over Bed</td>
                      <td>500 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Swing Over Carriage</td>
                      <td>260 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Distance Between Centers</td>
                      <td>425 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Max Spindle Speed</td>
                      <td>4000 rpm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M2} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 2 */}
            <br></br> <br></br>
            {/* ///Machine 3 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>3</td>
                      <td>Machine</td>
                      <td>CNC Milling Centre</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>JYOTI</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Model Number</td>
                      <td>RDX 20</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>X-Axis</td>
                      <td>820 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Y-Axis</td>
                      <td>510 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>RPM</td>
                      <td>8000 RPM</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ATC Tools</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Table-Length</td>
                      <td>1000x530mm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M3} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 3 */}
            <br></br> <br></br>
            {/* ///Machine 4 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>4</td>
                      <td>Machine</td>
                      <td>CNC Milling Centre</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>JYOTI</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Model Number</td>
                      <td>PX 20 nvu</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>X-Axis</td>
                      <td>510 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Y-Axis</td>
                      <td>510 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Z-Axis</td>
                      <td>510 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>RPM</td>
                      <td>6000 RPM</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ATC Tools</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Table-Size</td>
                      <td>660x460mm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M4} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 4 */}
            <br></br> <br></br>
            {/* ///Pagination */}
            <div class="pagination">
              <a>&laquo;</a>
              <a class="active" onClick={() => handleButtonClick(1)}>
                1
              </a>
              <a onClick={() => handleButtonClick(2)}>2</a>
              <a onClick={() => handleButtonClick(3)}>3</a>

              <a>&raquo;</a>
            </div>
          </div>
          {/* Page 2 */}
          <div style={{ display: activeDiv === 2 ? "block" : "none" }}>
            {/* ///Machine 5 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5</td>
                      <td>Machine</td>
                      <td>Horizontal Boring</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Machine Make</td>
                      <td>M/s Scharmann</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>X-Axis Bed Size</td>
                      <td>1200 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Y-Axis Bed Size</td>
                      <td>1200 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>X-Axis Travel</td>
                      <td>1200 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Y-Axis Travel</td>
                      <td>1200 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Z-Axis</td>
                      <td>1500 mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>C-Axis</td>
                      <td>360⁰ Hydraulic Rotation</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Spindle Size</td>
                      <td>125 mm</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>DRO</td>
                      <td>All three axis</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Tail Stock</td>
                      <td>Attached</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Fine Boring Bar Tools</td>
                      <td>∅30 to ∅400mm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M5} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 5 */}
            <br></br> <br></br>
            {/* ///Machine 6 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>6</td>
                      <td>Machine</td>
                      <td>Conventional Lathe</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Jeyam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>LENGTH OF BED</td>
                      <td>12′-6″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING OVER SADDLE (dia)</td>
                      <td> 32″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING IN GAP (dia)</td>
                      <td>36″</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M6} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 6 */}
            <br></br> <br></br>
            {/* ///Machine 7 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>7</td>
                      <td>Machine</td>
                      <td>Conventional Lathe</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Nikil</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>LENGTH OF BED</td>
                      <td>12′-6″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING OVER SADDLE (dia)</td>
                      <td> 32″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING IN GAP (dia)</td>
                      <td>36″</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M7} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 7 */}
            <br></br> <br></br>
            {/* ///Machine 8 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8</td>
                      <td>Machine</td>
                      <td>Conventional Lathe</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Turner</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>LENGTH OF BED</td>
                      <td>7′-6″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING OVER SADDLE (dia)</td>
                      <td> 24″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING IN GAP (dia)</td>
                      <td>30″</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M8} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 8 */}
            <br></br> <br></br>
            {/* ///Pagination */}
            <div class="pagination">
              <a>&laquo;</a>
              <a onClick={() => handleButtonClick(1)}>1</a>
              <a class="active" onClick={() => handleButtonClick(2)}>
                2
              </a>
              <a onClick={() => handleButtonClick(3)}>3</a>

              <a>&raquo;</a>
            </div>
          </div>

          {/* Page 3 */}
          <div style={{ display: activeDiv === 3 ? "block" : "none" }}>
            {/* ///Machine 9 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>9</td>
                      <td>Machine</td>
                      <td>Conventional Lathe</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>Modi Max</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>LENGTH OF BED</td>
                      <td>4′-6″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING OVER SADDLE (dia)</td>
                      <td> 12″</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>SWING IN GAP (dia)</td>
                      <td>18″</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M9} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 9 */}
            <br></br> <br></br>
            {/* ///Machine 10 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>10</td>
                      <td>Machine</td>
                      <td>Conventional Milling</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>HMT</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Table size</td>
                      <td>260 x 1200</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Longitudinal Travel Manual</td>
                      <td> 675</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Vertical Travel</td>
                      <td>440</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Cross Travel</td>
                      <td>340</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Head Tilt</td>
                      <td>45º Up & Down</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Head move</td>
                      <td>90º Left & Right Horizontal</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Head & Ram Rotate Parallel on body</td>
                      <td>360º</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Ram Travel</td>
                      <td>300</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M10} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 10 */}
            <br></br> <br></br>
            {/* ///Machine 11 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>11</td>
                      <td>Machine</td>
                      <td>MNTR</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Brand</td>
                      <td>First</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Product Type</td>
                      <td>Turret Type Milling Mact</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Automation Grade</td>
                      <td>Semi-Automatic</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Table Size</td>
                      <td>1050x300mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Longitudinal Travel Manual</td>
                      <td>750mm</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Vertical Travel</td>
                      <td>440</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Cross Travel</td>
                      <td>250</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Head Tilt</td>
                      <td>45º Up & Down</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Head move</td>
                      <td>30º Left & Right Horizontal</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                <img src={M11} className="img-fluid" alt="" />
              </div>
            </div>
            {/* ///Machine 11 */}
            <br></br> <br></br>
            {/* ///Machine 12 */}
            <div className="row">
              <div className="col-lg-8">
                <Table responsive bordered striped size="sm">
                  <thead style={{ backgroundColor: "#0c92dd", color: "white" }}>
                    <tr>
                      <td style={{ maxWidth: "30px" }}>Sl No.</td>
                      <td>Machine Specification</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>12</td>
                      <td>Machine</td>
                      <td>Fully Automatic Bandsaw</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Make</td>
                      <td>HABA Max</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Model</td>
                      <td>MCBFA150 HMA9RE</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Control</td>
                      <td>PLC</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Round mm</td>
                      <td>175</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Square mm</td>
                      <td>150 x 150</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Bar Stand</td>
                      <td>Roller Stand</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-4">
                {/* <img src={M10} className="img-fluid" alt=""/> */}
              </div>
            </div>
            {/* ///Machine 12 */}
            <br></br>
            <br></br>
            {/* ///Pagination */}
            <div class="pagination">
              <a>&laquo;</a>
              <a onClick={() => handleButtonClick(1)}>1</a>
              <a onClick={() => handleButtonClick(2)}>2</a>
              <a class="active" onClick={() => handleButtonClick(3)}>
                3
              </a>

              <a>&raquo;</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Machines;
