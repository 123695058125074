import React, { useState } from "react";
import logo from "./img/logo.jpeg";
import { Link } from "react-router-dom";

function Navbar() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <>
      <section className="fixed-top" style={{ marginTop: "-40px" }}>
        <div id="topbar" class="d-flex align-items-center">
          <div class="container d-flex justify-content-center justify-content-md-between">
            <div class="contact-info d-flex align-items-center">
              <i class="fa fa-envelope d-flex align-items-center">
                <a href="mailto:gloriousindustries16@gmail.com">
                  {" "}
                  gloriousindustries16@gmail.com
                </a>
              </i>
              <i class="fa fa-phone d-flex align-items-center ms-4">
                <a style={{ textAlign: "right" }} href="tel:+91 7868889600">
                  +917868889600
                </a>
              </i>
            </div>
            <div class="social-links d-none d-md-flex align-items-center">
              <a
                href="https://twitter.com/GloriousInd"
                target="_blank"
                class="twitter"
              >
                <i class="fa fa-twitter"></i>
              </a>
              <a href="" target="_blank" class="facebook">
                <i class="fa fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/gloriousindust/"
                target="_blank"
                class="instagram"
              >
                <i class="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/glorious-industries-40433918a/"
                target="_blank"
                class="linkedin"
              >
                <i class="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <header id="header" class="d-flex align-items-center">
          <div class="container d-flex justify-content-between">
            <div id="logo">
              <h1>
                <a>
                  <img id="logoimg" src={logo} alt="" /> Glorious
                  <span>Industries</span>
                </a>
              </h1>

              {/* <!-- <a href="index.html"><img src="assets/img/logo.png" alt=""></a>--> */}
            </div>

            <nav id="navbar" class="navbar">
              <ul>
                <li>
                  <Link class="nav-link scrollto active" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link class="nav-link scrollto" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link class="nav-link scrollto" to="/clients">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link class="nav-link scrollto " to="/portfolio">
                    Portfolio
                  </Link>
                </li>

                {/* <li class="dropdown">
                <a href="#">
                  <span>Drop Down</span> <i class="bi bi-chevron-down"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Drop Down 1</a>
                  </li>
                  <li class="dropdown">
                    <a href="#">
                      <span>Deep Drop Down</span>{" "}
                      <i class="bi bi-chevron-right"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Drop Down 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Drop Down 2</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 3</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 4</a>
                  </li>
                </ul>
              </li> */}
                <li>
                  <Link class="nav-link scrollto" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <i
                onClick={toggleVisibility}
                class="fa fa-list mobile-nav-toggle"
              ></i>
            </nav>
          </div>
        </header>

        {isVisible && (
          <div style={{ width: "100%", backgroundColor: "white" }}>
            <ul style={{ listStyle: "none", padding: "8px" }}>
              <li>
                <Link class="nav-link scrollto active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link class="nav-link scrollto" to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link class="nav-link scrollto" to="/clients">
                  Clients
                </Link>
              </li>
              <li>
                <Link class="nav-link scrollto " to="/portfolio">
                  Portfolio
                </Link>
              </li>

              <li>
                <Link class="nav-link scrollto" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        )}
      </section>
    </>
  );
}

export default Navbar;
