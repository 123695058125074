import React, { useState } from "react";

import G1 from "./img/gallery/1.png";
import G2 from "./img/gallery/2.png";
import G3 from "./img/gallery/3.png";
import G4 from "./img/gallery/4.png";
import G5 from "./img/gallery/5.png";
import G6 from "./img/gallery/6.png";
import G7 from "./img/gallery/7.png";
import G8 from "./img/gallery/8.png";
import G9 from "./img/gallery/9.png";
import G10 from "./img/gallery/10.png";
import G11 from "./img/gallery/11.png";
import G12 from "./img/gallery/12.png";
import GA from "./img/gallery/a.png";
import GB from "./img/gallery/b.png";
import GC from "./img/gallery/c.png";
import GD from "./img/gallery/d.png";
import GE from "./img/gallery/e.png";
import GF from "./img/gallery/f.png";
function Portfolio() {
  // State to track which div to show
  const [activeDiv, setActiveDiv] = useState(1);

  // Function to handle button click
  const handleButtonClick = (divNumber) => {
    setActiveDiv(divNumber);
  };
  return (
    <>
      <section id="portfolio" class="portfolio">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Our Portfolio</h2>
            <p>
              Where Innovation Meets Impact: A Tapestry of Achievements –
              Unveiling Our Distinguished Portfolio.
            </p>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li onClick={() => handleButtonClick(1)}>Machine Parts</li>
                <li onClick={() => handleButtonClick(2)}>Fabricated Parts</li>
                <li onClick={() => handleButtonClick(3)}>Bending Parts</li>
              </ul>
            </div>
          </div>

          <div class="row portfolio-container">
            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G1} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G2} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G3} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G4} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G5} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G6} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 1 ? "block" : "none" }}
            >
              <img src={G7} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GA} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GB} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GC} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GD} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GE} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 2 ? "block" : "none" }}
            >
              <img src={GF} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 3 ? "block" : "none" }}
            >
              <img src={G8} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 3 ? "block" : "none" }}
            >
              <img src={G9} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 3 ? "block" : "none" }}
            >
              <img src={G10} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 3 ? "block" : "none" }}
            >
              <img src={G11} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>

            <div
              class="col-lg-3 col-md-6 portfolio-item"
              style={{ display: activeDiv === 3 ? "block" : "none" }}
            >
              <img src={G12} class="img-fluid" alt="" />
              <div class="portfolio-info"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
