import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Contact from "./Components/Contact";
import C1 from "./Components/img/c1.jpg";
import C2 from "./Components/img/c2.jpg";
import Portfolio from "./Components/Portfolio";
import Clients from "./Components/Clients";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />

              <Footer />
            </>
          }
        />

        <Route
          path="/about"
          element={
            <>
              <Navbar />
              <br></br> <br></br> <br></br> <br></br> <br></br>
              <br></br>
              <About />
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <img src={C1} alt="" className="img-fluid" />
                  </div>
                  <div className="col-lg-4">
                    <img src={C2} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
              <Footer />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <br></br> <br></br> <br></br> <br></br> <br></br>
              <br></br>
              <Contact />
              <Footer />
            </>
          }
        />

        <Route
          path="/clients"
          element={
            <>
              <Navbar />
              <br></br> <br></br> <br></br> <br></br> <br></br>
              <br></br>
              <Clients />
              <Footer />
            </>
          }
        />

        <Route
          path="/portfolio"
          element={
            <>
              <Navbar />
              <br></br> <br></br> <br></br> <br></br> <br></br>
              <br></br>
              <Portfolio />
              <Footer />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
