import React from "react";

function Footer() {
  return (
    <>
      <footer id="footer">
        <div class="container">
          <div class="copyright">
            &copy; Copyright <strong>Glorious Industries</strong>. All Rights
            Reserved
          </div>
          <div class="credits">
            Designed by{" "}
            <a href="https://www.falconxx.com/" target="_blank">
              FXCTS
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
