import React from "react";

import About from "./About";
import ClientPC from "./img/Clients/ClientsA.png";
import ClientMB from "./img/Clients/ClientsB.png";

import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Machines from "./Machines";
import Clients from "./Clients";

function Home() {
  return (
    <>
      <section id="hero">
        <div class="hero-content">
          <h2 style={{ marginTop: "9vh" }}>
            Empowering <span>Industries</span>,
            <br />
            Inspiring <span>Innovations</span>
          </h2>
          <div>
            <a href="#about" class="btn-get-started scrollto">
              Get Started
            </a>
            <a href="#portfolio" class="btn-projects scrollto">
              Our Projects
            </a>
          </div>
        </div>

        <div class="hero-slider swiper">
          {/* <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              style="background-image: url('assets/img/hero-carousel/1.jpg');"
            ></div>
            <div
              class="swiper-slide"
              style="background-image: url('assets/img/hero-carousel/2.jpg');"
            ></div>
            <div
              class="swiper-slide"
              style="background-image: url('assets/img/hero-carousel/3.jpg');"
            ></div>
            <div
              class="swiper-slide"
              style="background-image: url('assets/img/hero-carousel/4.jpg');"
            ></div>
            <div
              class="swiper-slide"
              style="background-image: url('assets/img/hero-carousel/5.jpg');"
            ></div>
          </div> */}
        </div>
      </section>

      <About />

      <Clients />

      <Portfolio />

      <Machines />

      <Contact />
    </>
  );
}

export default Home;
