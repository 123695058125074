import React from "react";
import Aboutt from "./img/about.jpg";
import GoToTop from "../GoToTop";
function About() {
  return (
    <>
      <GoToTop />
      <section id="about">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-5 about-img">
              <img src={Aboutt} alt="" />
            </div>

            <div class="col-lg-6 content">
              <h2>ABOUT US</h2>
              <p>
                Glorious Industries, is a Vellore based, We engaged in
                Development and Production of Precision Metal Parts & Products.
                <br></br> <br></br>
                Glorious Industries is an Exceptional Quality - Highly Volume
                oriented manufacturing company serving. Our strength is our Team
                of Engineers and skilled employees whose passion is to provide
                high quality products & services to our Customer's.
                <br></br> <br></br>
                We Provide Design and Development, Manufacturing support,
                outstanding customer service, consistent product quality, and
                on-time delivery to our Customers.
              </p>

              {/* <ul>
           <li>
             <i class="fa fa-check-circle"></i> Glorious Industries is
             committed to improve its product quality and production
             processes by identifying the potential Risks and Opportunities
             & addressing it through continual improvements to satisfy all
             the applicable requirements of interested parties.
           </li>
           <li>
             <i class="bi bi-check-circle"></i> Duis aute irure dolor in
             reprehenderit in voluptate velit.
           </li>
           <li>
             <i class="bi bi-check-circle"></i> Ullamco laboris nisi ut
             aliquip ex ea commodo consequat. Duis aute irure dolor in
             reprehenderit in voluptate trideta storacalaperda mastiro
             dolore eu fugiat nulla pariatur.
           </li>
         </ul> */}
            </div>
          </div>
        </div>
      </section>
      <section id="services">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div class="box">
                <h4 class="title">
                  <a href="">Quality Policy</a>
                </h4>
                <p class="description">
                  Glorious Industries is committed to improve its product
                  quality and production processes by identifying the potential
                  Risks and Opportunities & addressing it through continual
                  improvements to satisfy all the applicable requirements of
                  interested parties.
                </p>
              </div>
            </div>

            <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div class="box">
                <h4 class="title">
                  <a href="">Mission</a>
                </h4>
                <p class="description">
                  Glorious Industries performance is committed to a policy of
                  continuous improvement & shall strive for excellence in all
                  its endeavors. Each individual in the team shall work towards
                  total quality culture aiming for customer satisfaction.
                </p>
              </div>
            </div>

            <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
              <div class="box">
                <h4 class="title">
                  <a href="">Vision</a>
                </h4>
                <p class="description">
                  To be a Professional organization, manufacturing the highest
                  quality performance vide arrange of products while enhancing
                  our relations with all our customers India & Export.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
