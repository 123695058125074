import React from "react";
import GoToTop from "../GoToTop";
function Contact() {
  return (
    <>
      <GoToTop />
      <section id="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Contact Us</h2>
            <p>
              Your Journey Starts with a Conversation: Contact Us for Solutions
              Tailored to Your Needs and Goals.
            </p>
          </div>

          <div class="row contact-info">
            <div class="col-md-4">
              <div class="contact-address">
                <i class="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <address>Vellore, TamilNadu</address>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-phone">
                <i class="bi bi-phone"></i>
                <h3>Phone Number</h3>
                <p>
                  <a href="tel:+91 7868889600">+91 7868889600</a>
                </p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-email">
                <i class="bi bi-envelope"></i>
                <h3>Email</h3>
                <p>
                  <a href="mailto:gloriousindustries16@gmail.com">
                    gloriousindustries16@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container mb-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.600010892918!2d79.1984047!3d12.9462367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad39eae5202e5f%3A0xa1cb8737620c0736!2sGLORIOUS%20INDUSTRIES!5e0!3m2!1sen!2sin!4v1702015372058!5m2!1sen!2sin"
            width="100%"
            height="380"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div class="container">
          <div class="form">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="form-group col-md-6">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div class="form-group col-md-6 mt-3 mt-md-0">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>

              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>

              <div class="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
